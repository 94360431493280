<template>
  <div>
    <div class="">
      <p class="font-medium text-primary">Mobile</p>
      <vs-input
        v-validate="'required|min:3'"
        data-vv-validate-on="blur"
        name="mobile"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        v-model="mobile"
        class="w-full"
        @keyup.enter="loginJWT"
      />
      <span class="text-danger text-sm">{{ errors.first("mobile") }}</span>
    </div>
    <div class="">
      <!-- <vs-checkbox
        v-model="isTermsConditionAccepted"
        class="mt-6"
      ></vs-checkbox> -->
      <p class="mt-6">
        By logging in you agree to accept our
        <a class="cursor-pointer" @click.prevent="openTerms"
          >Terms &amp; Conditions</a
        >.
      </p>
      <p class="mt-2 text-primary">
        Not yet Registered?
        <a
          class="cursor-pointer text-primary underline"
          @click="navigateToRegister"
          >Register</a
        >
      </p>
    </div>
    <vs-button type="border" to="/pages/kotakregister" class="mt-6"
      >Go to register</vs-button
    >
    <div class="flex flex-wrap justify-between mb-3 mt-6">
      <!-- <vs-button type="border" @click="registerUser">Register</vs-button> -->
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");
import jwt from "jsonwebtoken";
const uuid = require("@/Core/config/uuid");
import reCaptchaMixin from "@/Client/Kotak/mixins/reCaptchaMixin";
import validationMixin from "@/Core/mixins/validationMixin";
const profileRepository = RepositoryFactory.get("profile");

export default {
  mixins: [reCaptchaMixin, validationMixin],
  data() {
    return {
      mobile: "",
      checkbox_remember_me: false,
      sendToIndividualPost: false,
      individualPostData: {},
      sendToFXCalendar: false,
      sendToMMCalendar: false,
      sendToRoute: "",
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.mobile != "";
    },
  },
  watch: {
    mobile(val) {
      this.specialCharacterValidation(val, "mobile");
    },
  },
  methods: {
    navigateToRegister() {
      this.$router.push("/pages/customadminregister").catch(() => {});
    },
    async registerpage() {
      await this.$router.push({ name: "kotakregister" });
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()
        this.$router.push("/community-home").catch(() => {});
        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    async loginJWT() {
      const regex = /^[0-9]*$/;
      const result = this.mobile.trim().match(regex);
      if (this.mobile.length > 10 || this.mobile.length < 10) {
        return this.$swal("", "Please Enter 10 digit Mobile Number", "error");
      }
      if (result == null) {
        return this.$swal("", "Please Enter Valid Mobile Number", "error");
      }
      this.$vs.loading();
      // const reCaptcha = await this.recaptcha("readerLogin");
      const uniqueId = uuid.getUniqueBrowserId();
      localStorage.setItem("biri", uniqueId);
      var obj = {
        communityName: currentCommunityName.communityName,
        uniqueId: uniqueId,
        mobile: this.mobile,
        // token: reCaptcha
      };
      const { data } = await userRepository.getNormalUser(obj);

      // console.log(data, "dfsdfdsfdsf");
      if (data.data.ResponseCode == 100) {
        if (
          data.data.userData.usersDetails_Status == 1 &&
          data.data.userData.usersDetails_BlockStatus != 1
        ) {
          this.$vs.loading.close();
          localStorage.setItem(
            "bsacl",
            jwt.sign(data.data, "my_secret_key", { expiresIn: 60 * 60 * 24 })
          );
          if (data.data.isNormalUser) {
            this.$acl.change("normal");
          }
          localStorage.setItem("token", data.token);
          this.$session.start();
          this.$session.set("jwt", data.token);

          if (this.sendToIndividualPost) {
            if (this.$router.currentRoute.params.crn) {
              const payload = {
                userid: data.data.userID,
                crn: this.$router.currentRoute.params.crn,
              };
              profileRepository.resourceeditUserProfileCRN(payload);
            }
            this.routeToIndividualPost();
            // console.log(this.$router.currentRoute.params.crn);
          } else if (this.sendToFXCalendar) {
            this.$router.push({ name: "fx-calendar" });
          } else if (this.sendToMMCalendar) {
            this.$router.push({ name: "money-market-calendar" });
          } else if (this.sendToRoute) {
            this.$router.push({ name: this.sendToRoute });
          } else {
            this.$router.push({ name: "latest-home" }).catch(() => {});
            // window.location.reload()
          }
        } else {
          this.$vs.loading.close();
          this.$swal({
            icon: "warning",
            title:
              "You account has been blocked. Kindly contact admin to unblock your account",
          });
        }
      } else if (data.data.ResponseCode == 102) {
        this.$swal({
          icon: "warning",
          title: "You are not register with us",
          timer: 2000,
        });
        this.$vs.loading.close();
        if (this.sendToIndividualPost) {
          this.$router
            .push({
              name: "page-kotak-register",
              params: {
                toIndividualPost: true,
                pid: this.$router.currentRoute.params.pid,
                mobile: this.mobile,
                crn: this.$router.currentRoute.params.crn,
              },
            })
            .catch(() => {});
        } else if (this.sendToFXCalendar) {
          this.$router
            .push({
              name: "page-kotak-register",
              params: {
                toFXCalendar: true,
              },
            })
            .catch(() => {});
        } else if (this.sendToMMCalendar) {
          this.$router
            .push({
              name: "page-kotak-register",
              params: {
                toMMCalendar: true,
              },
            })
            .catch(() => {});
        }
      } else if (this.sendToRoute) {
        this.$router
          .push({
            name: this.sendToRoute,
            params: {
              toRoute: this.sendToRoute,
            },
          })
          .catch(() => {});
      } else {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Something went wrong",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    openTerms() {
      // this.$router.push("/pages/terms").catch(() => {});
      let routeData = this.$router.resolve({ name: "page-terms" });
      window.open(routeData.href, "_blank");
    },
    routeToIndividualPost() {
      let url = "/readers-view?pid=" + this.$router.currentRoute.params.pid;
      if (this.$router.currentRoute.params.crn) {
        url = url + "&crn=" + this.$router.currentRoute.params.crn;
      }
      setTimeout(() => {
        this.$router.push(url).catch(() => {});
      }, 1000);
    },
    registerUser() {
      this.$router.push("/pages/kotakregister").catch(() => {});
    },
    showLoginError(error) {
      this.$vs.loading.close();
      this.$vs.notify({
        title: "Error",
        text: error.message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
      });
    },
  },
  mounted() {
    if (this.$router.currentRoute.params.toIndividualPost) {
      this.sendToIndividualPost = true;
      this.individualPostData = this.$router.currentRoute.params.pid;
    }
    if (this.$router.currentRoute.params.toFXCalendar) {
      this.sendToFXCalendar = true;
    }
    if (this.$router.currentRoute.params.toMMCalendar) {
      this.sendToMMCalendar = true;
    }
    if (this.$router.currentRoute.params.toRoute) {
      this.sendToRoute = this.$router.currentRoute.params.toRoute;
    }
  },
};
</script>
<style>
body {
  width: 100%;
}
</style>
